import cz from "./cs-CZ/amrest-franchise-cz-cs-CZ.json";
import en from "./en-US/amrest-franchise-cz-en-US.json";
import { changeLanguageDataLayer } from "../../DataLayer";
import { changeLanguage as piwikChangeLanguage } from "../../PiwikDataLayer";

let language = window.location.pathname.substring(0,4) === "/en/" ? "en" : "cz";

function changeLanguage(to) {
  if (language === to) {
    return;
  }
  window.document.querySelector("body").classList.remove(language);
  window.document.querySelector("body").classList.add(to);

  language = language === "cz" ? "en" : "cz";
  changeLanguageDataLayer(language);
  piwikChangeLanguage(language) // piwik changeLanguage
  document.documentElement.lang = language;
}

function getLanguage() {
  return language;
}

const translations = new Proxy([], {
  get: function (target, name) {
    if (language === "cz") {
      return cz[name];
    }
    return en[name];
  },
});

export default translations;
export { changeLanguage, getLanguage };
