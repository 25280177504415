import { withRouter } from "react-router-dom";
import { getLanguage } from "../i18n/Translations";
import { virtualVersion } from "../../DataLayer";

let added = false;
let language = getLanguage();

function FakeRouter({ history }) {
  virtualVersion();

  const switchToEn = (url) => {
    switch (url) {
      case "/":
        history.replace("/en/");
        break;
      case "/kontakt":
        history.replace("/en/contact");
        break;
      case "/vyhody":
        history.replace("/en/benefits");
        break;
      case "/postup":
        history.replace("/en/how-to-start");
        break;

      default:
        break;
    }
  };

  const switchToPl = (url) => {
    switch (url) {
      case "/en/":
        history.replace("/");
        break;
      case "/en/contact":
        history.replace("/kontakt");
        break;
      case "/en/benefits":
        history.replace("/vyhody");
        break;
      case "/en/how-to-start":
        history.replace("/postup");
        break;

      default:
        break;
    }
  };

  if (language !== getLanguage()) {
    added = false;
    language = getLanguage();

    if (language === "cz") {
      switchToPl(window.location.pathname);
    } else {
      switchToEn(window.location.pathname);
    }
  }



  if (!added) {
    history.listen((location) => {
      if (language === "en") {
        let url = location.pathname;
        switchToEn(url);
      }
    });

    added = true;
  }

  return <></>;
}

export default withRouter(FakeRouter);
